import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import HomeIconFilled from '@material-ui/icons/Home';
import ChatIconFilled from '@material-ui/icons/ChatBubble';

import { Button } from 'react-bootstrap';
import ContactsIcon from '@mui/icons-material/Contacts';
import MoreIcon from '@material-ui/icons/MoreHorizOutlined';
import ClassIcon from '@mui/icons-material/Class';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import PeopleIcon from '@mui/icons-material/People';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

// import ChatIcon from '@mui/icons-material/Chat';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import { getCurrentDashboardData, getNavbarData } from '../../../redux/reducers/dashboard.reducer';
import {
  getRoleArray,
  getClientId,
  getClientUserId,
} from '../../../redux/reducers/clientUserId.reducer';

import './BottomNavigation.scss';
import ReOrderTabs from '../../BottomNavigation/ReOrderTabs';
import { get } from '../../../Utilities';

// import data from './BottomRoutes';

const BottomNavigation = (props) => {
  const {
    history,
    roleArray,
    activeNav,
    dashboardData,
    clientId,
    clientUserId,
    navbarData,
  } = props;
  const [scrolledUp, setScrolledUp] = useState(false);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [active, setActive] = useState(activeNav);
  const [reorderClicked, setReorderClicked] = useState(false);
  const [featureslist, setfeatureslist] = useState([]);
  const draggerRef = useRef(null);
  const roleId = roleArray.includes(1)
    ? 1
    : roleArray.includes(2)
    ? 2
    : roleArray.includes(3)
    ? 3
    : 4;

  // Get the transformed array

  useEffect(() => {
    const { feature, featureOrder } = dashboardData;
    /* eslint-disable */

    const uniqueFeatures = new Set();

    const filtered = featureOrder
      ?.map((ele) => {
        if (Object.keys(feature).includes(ele) && !uniqueFeatures.has(ele)) {
          uniqueFeatures.add(ele);
          feature[ele].keyName = ele;
          return feature[ele];
        }
      })
      .filter(Boolean); // Remove undefined values resulting from unhandled conditions

    setFilteredFeatures(filtered);
  }, []);

  useEffect(() => {
    if (scrolledUp) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [scrolledUp]);

  const gotoAnywhere = (route, flag = false, state = null) => {
    route = '/' + route;
    if (flag) {
      setScrolledUp(false);
      setActive(activeNav);
    }
    if (!state) {
      history.push(route);
    } else {
      history.push({
        pathname: route,
        state: state,
      });
    }
  };

  const [NavArray, setNavArray] = useState([]);
  // useEffect(() => {
  //   setNavArray(
  //     transformedArray.filter(
  //       (elem) =>
  //         elem.feature_name !== 'aboutUs' &&
  //         elem.feature_name !== 'contactUs' &&
  //         elem.feature_name !== 'connectWithUs' &&
  //         elem.feature_name !== 'share' &&
  //         elem.feature_name !== 'posters' &&
  //         elem.feature_name !== 'videos' &&
  //         elem.feature_name !== 'starPerformers' &&
  //         elem.feature_name !== 'testimonial' &&
  //         elem.feature_name !== 'admissionForm' &&
  //         elem.feature_name !== 'admission' &&
  //         elem.feature_name !== 'onlineAssignment',
  //     ),
  //   );
  // }, []);

  const goToHome = () => {
    history.push('/');
  };

  const goToHomeWorkCreator = () => {
    history.push({ pathname: '/homework', state: { letsGo: true } });
  };

  const goToLiveClasses = () => {
    history.push({ pathname: '/liveclasses' });
  };

  const goToAdmissions = () => {
    history.push({ pathname: '/admissions' });
  };

  const goToNoticeBoard = () => {
    history.push({ pathname: '/noticeboard' });
    // console.log(history);
  };

  const goToClickedPage = (page) => {
    let link = '';
    if (page == 'noticeBoard') {
      link = 'noticeboard';
    } else if (page == 'offlineAssignment') {
      link = 'offlineassignments';
    } else if (page == 'homeworkCreator') {
      link = 'homework';
    } else if (page == 'analysis') {
      link = 'analysis/teacher';
    } else if (page == 'admission') {
      link = 'admissions';
    } else {
      link = page;
    }

    setScrolledUp(false);
    setActive(activeNav);
    document.body.style.overflow = 'auto';
    gotoAnywhere(link);
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleClicked = () => {
    history.push('/reorder-tabs');
  };

  const moreClicked = () => {
    setScrolledUp((prev) => !prev);
    if (!scrolledUp) {
      setActive('more');
    } else {
      setActive(activeNav);
    }
  };

  const backDropClicked = () => {
    setActive(activeNav);
    setScrolledUp(false);
  };
  // UseState of the handle Change..!!
  console.log(clientId);
  const handleRoute = (name) => {
    if (name == 'chats') {
      history.push('/conversations');
    } else if (name == 'attendance') {
      history.push('/attendance');
    } else if (name == 'analysis') {
      history.push('/analysis/teacher');
    } else if (name == 'liveClasses') {
      history.push('/liveclasses');
    } else if (name == 'displayPage') {
      history.push('/displaypage');
    } else if (name == 'studyBin') {
      history.push('/studybin');
    } else if (name == 'crm') {
      history.push('/crm');
    } else if (name == 'noticeBoard') {
      history.push('/noticeboard');
    } else if (name == 'homeworkCreator') {
      history.push('/homework');
    } else if (name == 'myCourses') {
      history.push('/courses/teachercourse');
    } else if (name == 'offlineAssignment') {
      history.push('/offlineassignments');
    } else if (name == 'liveClasses') {
      history.push('/liveclasses');
    } else if (name == 'fees') {
      history.push('/teacherfees');
    } else if (name == 'courses') {
      history.push({ pathname: '/courses', state: { type: 'allCourses' } });
    } else if (name == 'admission') {
      history.push({ pathname: '/admissions', state: { type: 'allCourses' } });
    } else {
      history.push('/notfound');
    }
  };
  return (clientId === 25 || clientId === 137 || clientId === 1632) &&
    (roleArray.includes(4) || roleArray.includes(5) || roleArray.includes(3)) ? (
    /* eslint-disable */
    <>
      {scrolledUp ? <div onClick={backDropClicked} className='backDrop' /> : null}

      <div className={`bottomScrollBar${scrolledUp ? ' scrolledUp' : ''}`}>
        <div>{reorderClicked && <ReOrderTabs />}</div>
        <div className='horizontalLineContainer'>
          <div
            ref={draggerRef}
            onTouchEnd={backDropClicked}
            onClick={backDropClicked}
            className='horizontalLine'
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* Your other bottom sheet content */}
          <Button type='submit' variant='boldText' className='px-3 mt-4' onClick={handleClicked}>
            Reorder
          </Button>
        </div>
        <div className='squaresC'>
          {filteredFeatures?.map((ele) => {
            if (ele.keyName === 'share') return null;
            if (ele.keyName.includes('dynamicCard')) return null;
            if (ele.keyName === 'chats') return null;
            if (ele.keyName === 'courses') return null;
            if (ele.keyName === 'studyBin') return null;
            return (
              <div
                onClick={() => handleRoute(ele.keyName)}
                key={ele.keyName}
                className={`square${ele.keyName === activeNav ? ' activeSquare' : ''}`}
              >
                <img src={ele.feature_icon} className='BNImage' />
                <p className='textFeatureNameBN'>
                  {ele.client_feature_name.startsWith('<')
                    ? 'Homework Creator'
                    : ele.client_feature_name}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div className='bottomNavigation'>
        <div
          onClick={goToHome}
          className={`bottomNavItems${active === 'home' ? ' activeNav' : ''}`}
        >
          {active === 'home' ? <HomeIconFilled /> : <HomeIcon />}
          <p className='bottomNavSmallText'>Home</p>
        </div>

        {navbarData?.slice(0, 3).map((item, index) => (
          <div
            key={index}
            className={`bottomNavItems${
              !['home', 'studyBin', 'courses', 'chats', 'noticeBoard'].includes(active) ? '' : ''
            }`}
            // className={`bottomNavItems${index ? ' activeNav' : ''}`}
            onClick={() => handleRoute(item.feature_name)}
          >
            {item.feature_name == 'chats' ? <ChatIconFilled /> : null}
            {item.feature_name == 'fees' ? <CurrencyRupeeIcon /> : null}
            {item.feature_name == 'liveClasses' ? <VideoCameraFrontIcon /> : null}
            {item.feature_name == 'videos' ? <VideoCameraFrontIcon /> : null}
            {item.feature_name == 'starPerformers' ? <MilitaryTechIcon /> : null}
            {item.feature_name == 'analysis' ? <AssignmentIndOutlinedIcon /> : null}
            {item.feature_name == 'onlineAssignment' ? <AssignmentIndOutlinedIcon /> : null}
            {item.feature_name == 'courses' ? <SchoolOutlinedIcon /> : null}
            {item.feature_name == 'homeworkCreator' ? <SchoolOutlinedIcon /> : null}
            {item.feature_name == 'myCourses' ? <ClassIcon /> : null}
            {item.feature_name == 'studyBin' ? <AddToDriveIcon /> : null}
            {item.feature_name == 'attendance' ? <PeopleIcon /> : null}
            {item.feature_name == 'noticeBoard' ? <NoteAltIcon /> : null}
            {item.feature_name == 'crm' ? <ContactsIcon /> : null}
            {item.feature_name == 'displayPage' ? <PeopleIcon /> : null}
            <p className='bottomNavSmallText'>
              {capitalizeFirstLetter(item.feature_english_display_name)}
            </p>
          </div>
        ))}

        {roleArray.includes(4) || roleArray.includes(5) ? (
          <div
            onClick={moreClicked}
            // className={`bottomNavItems${
            //   !['home', 'studyBin', 'courses', 'chats'].includes(active) ? ' ' : ''
            // }`}
            className={`bottomNavItems${active === 'more' ? ' activeNav' : ''}`}
          >
            <MoreIcon />
            <p className='bottomNavSmallText'>More</p>
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    dashboardData: getCurrentDashboardData(state),
    roleArray: getRoleArray(state),
    clientId: getClientId(state),
    clientUserId: getClientUserId(state),
    navbarData: getNavbarData(state),
  };
};

export default connect(mapStateToProps, null)(BottomNavigation);

BottomNavigation.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  roleArray: PropTypes.instanceOf(Array).isRequired,
  activeNav: PropTypes.string.isRequired,
  dashboardData: PropTypes.instanceOf(Object).isRequired,
};
